import {ThemeProvider, UiKitTheme} from '@lavka/ui-kit';
import {setLangCookie} from '@lavka-birds/b2b-common';
import React from 'react';
import {type QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import * as ReactRedux from 'react-redux';

import {type UILanguage, i18n, I18nProvider} from '@/src/i18n';
import type {Store} from 'client/store/configure-store';

const handleUpdateLang = (uiLang: UILanguage) => {
    setLangCookie(uiLang);
};

interface Props {
    store: Store;
    queryClient: QueryClient;
    initLang: UILanguage;
}

export const BaseAppWrapper: React.FC<Props> = ({initLang, store, queryClient, children}) => {
    return (
        <ReactRedux.Provider store={store}>
            <I18nProvider initLang={initLang} i18n={i18n} onUpdateLang={handleUpdateLang}>
                <QueryClientProvider client={queryClient}>
                    <ThemeProvider theme={UiKitTheme.Default} dark={false}>
                        {children}
                        {process.env.__NO_DEV_TOOLS__ ? null : (
                            <ReactQueryDevtools containerElement="div" position="bottom-right" />
                        )}
                    </ThemeProvider>
                </QueryClientProvider>
            </I18nProvider>
        </ReactRedux.Provider>
    );
};

export type {Props};
