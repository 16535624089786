import {
    type PageHeaderLanguageSelectProps,
    type PageHeaderSmartCompaniesMenuProps,
    PageHeader,
    PageHeaderLanguageSelect,
    PageHeaderSmartCompaniesMenu,
    PageHeaderSmartServiceMenu,
    PageHeaderUserMenuSettings,
    ServiceIconType
} from '@lavka/ui-kit';
import {IdentityBasketUserKind, ROOT_COMPANY_SLUG} from '@lavka-birds/b2b-common';
import {block, useActions} from '@lavka-birds/client';
import {deserializeError, UnknownError} from '@lavka-birds/errors';
import {push} from 'connected-react-router';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {type UILanguage, useI18nContext} from '@/src/i18n';
import {generatePathLogout, generatePathUsersList} from '@/src/lib/generate-path';
import {config} from 'client/config';
import {logger} from 'client/lib/error-logger';
import {selectAvailableUILanguages, selectBaseHost, selectCompanySlug, selectUser} from 'client/store/app';
import {useCurrentUserPagePath} from 'client/store/router/router-actions';
import {Feature} from 'types/features';

import styles from './header.css';

const cn = block(styles, 'header');

const activeService = {
    id: 'user-cabinet',
    title: 'ID',
    icon: ServiceIconType.ID
};

interface GetServicesErrorData {
    response?: Response;
    error?: unknown;
}

async function buildError({response, error: originalError}: GetServicesErrorData): Promise<unknown> {
    if (originalError) {
        return originalError;
    }

    if (response) {
        try {
            const json = await response.json();
            return deserializeError(json, response);
            // eslint-disable-next-line no-empty
        } catch {}
    }

    const cause = new Error();
    cause.message = response?.statusText || 'Network response was not ok';
    const instance = new UnknownError(cause);
    instance.statusCode = response?.status || 0;

    return instance;
}

function isErrorInstance(error: unknown): error is Error {
    const errorTyped = error as Error | undefined;

    return typeof errorTyped?.name === 'string' && typeof errorTyped?.message === 'string';
}

async function handleGetHeaderDataError(errorData: GetServicesErrorData) {
    const error = await buildError(errorData);

    logger.logError(isErrorInstance(error) ? error : new Error('Unknown error'), {
        source: 'network',
        prefix: 'requestApi header getServices error:'
    });
    throw error;
}

interface HeaderProps {
    centerContent?: React.ReactNode;
}

export function Header({centerContent}: HeaderProps) {
    const {i18n, setUiLang, uiLang} = useI18nContext();

    const user = useSelector(selectUser);
    const userInfo = React.useMemo(() => {
        if (!user) {
            return undefined;
        }

        return {
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            avatar: user.picture
        };
    }, [user]);

    const companySlug = useSelector(selectCompanySlug);
    const baseHost = useSelector(selectBaseHost);
    const {headerColorScheme} = config.app;

    const availableUILanguages = useSelector(selectAvailableUILanguages);
    const availableLangsOptions = useMemo(() => {
        return availableUILanguages.map((code) => ({code}));
    }, [availableUILanguages]);

    const handleUILangChange = React.useCallback<Required<PageHeaderLanguageSelectProps>['onChange']>(
        (code) => setUiLang(code as UILanguage),
        [setUiLang]
    );

    const isUserPageInDifferentPartner =
        user?.kind === IdentityBasketUserKind.GLOBAL && companySlug !== ROOT_COMPANY_SLUG;
    const openPage = useActions(push);
    const userPagePath = useCurrentUserPagePath();
    const openUserPage = React.useCallback(() => {
        if (!userPagePath) {
            return;
        }

        openPage(userPagePath);
    }, [openPage, userPagePath]);

    // Если переходим в глобального партнёра, делаем браузерный переход. Если нет - переходим через react-router.
    const userCabinetLinkProps = isUserPageInDifferentPartner
        ? ({userCabinetLink: userPagePath, userCabinetLinkTarget: '_self'} as const)
        : {onUserInfoClick: openUserPage};

    const handleLogoutClick = React.useCallback(() => {
        if (!companySlug) {
            return;
        }

        window.location.href = generatePathLogout({partnerOrCompanyId: companySlug});
    }, [companySlug]);

    const handleCompanyChange = React.useCallback<Required<PageHeaderSmartCompaniesMenuProps>['onItemClick']>(
        ({slug}) => {
            window.location.href = generatePathUsersList({partnerOrCompanyId: slug});
        },
        []
    );

    return (
        <PageHeader
            {...userCabinetLinkProps}
            leftContent={
                <PageHeaderSmartServiceMenu
                    colorScheme={headerColorScheme}
                    activeService={activeService}
                    baseHost={baseHost}
                    companySlug={companySlug}
                    onServicesRequestError={handleGetHeaderDataError}
                />
            }
            rightContent={
                <PageHeaderSmartCompaniesMenu
                    className={cn('companies-menu')}
                    baseHost={baseHost}
                    activeCompanySlug={companySlug}
                    onCompaniesRequestError={handleGetHeaderDataError}
                    onItemClick={handleCompanyChange}
                />
            }
            userInfo={userInfo}
            userMenuContent={
                config.features[Feature.ALLOW_LANGUAGE_SWITCH] ? (
                    <PageHeaderUserMenuSettings title={i18n('header', 'user-menu-title')}>
                        {[
                            <PageHeaderLanguageSelect
                                key="interface-language"
                                title={i18n('header', 'ui-lang-title')}
                                currentValue={uiLang}
                                languageOptions={availableLangsOptions}
                                onChange={handleUILangChange}
                            />
                        ]}
                    </PageHeaderUserMenuSettings>
                ) : undefined
            }
            centerContent={centerContent}
            logoutButtonText={companySlug ? i18n('user-view', 'logout') : undefined}
            onLogoutClick={companySlug ? handleLogoutClick : undefined}
        />
    );
}
