import {type QueryFunctionContext, type UseInfiniteQueryOptions, useInfiniteQuery} from 'react-query';

import type {ApiResponseError} from '@/src/errors';
import {usersClient} from 'client/api/client';
import type {IdmUserRoleStatus} from 'types/idm';
import type {GetUsersPaginationToken, GetUsersResponse, IdentityBasketUserKind} from 'types/users';

interface UseGetUsersListRequestParams {
    searchText?: string;
    userKind?: IdentityBasketUserKind;
    userRoleStatuses?: IdmUserRoleStatus[];
}

interface UseGetUsersListParams {
    requestParams?: UseGetUsersListRequestParams;
    reactQueryParams?: Pick<UseInfiniteQueryOptions<GetUsersResponse, ApiResponseError>, 'enabled' | 'onSuccess'>;
}

export const getUsersListQueryKey = (params?: UseGetUsersListRequestParams) => {
    const userRolesStatusesSorted = params?.userRoleStatuses?.slice();
    userRolesStatusesSorted?.sort();

    return [{scope: 'users-list', ...params, userRoleStatuses: userRolesStatusesSorted}] as const;
};

export function useGetUsersList({
    requestParams = {},
    reactQueryParams: {enabled, onSuccess} = {}
}: UseGetUsersListParams = {}) {
    const {searchText: searchTextParam, userKind, userRoleStatuses} = requestParams;
    const searchText = searchTextParam?.trim() || undefined;

    const queryKey = getUsersListQueryKey({...requestParams, searchText});

    return useInfiniteQuery<GetUsersResponse, ApiResponseError>(
        queryKey,
        ({pageParam: paginationToken}: QueryFunctionContext<typeof queryKey, GetUsersPaginationToken>) =>
            usersClient.getUsersList({
                search_text: searchText,
                user_kind: userKind,
                user_role_statuses: userRoleStatuses,
                pagination_token: paginationToken
            }),
        {
            enabled,
            onSuccess,
            getNextPageParam: (lastPage) => lastPage.paginationToken
        }
    );
}
