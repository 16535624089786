import {block} from '@lavka-birds/client';
import React from 'react';
import {useSelector} from 'react-redux';

import {NotificationIcon} from './components/notification-icon';
import {UsersImportNotification} from './components/users-import-notification';
import {useNotificationAutoClose} from './hooks/use-notification-auto-close';
import {useNotificationCloseAnimation} from './hooks/use-notification-close-animation';
import {selectNotifications} from './notifications-selectors';
import {type NotificationData, NotificationType} from './types';
import {isStandardNotification} from './utils';

import styles from './notifications.css';

const cn = block(styles, 'notifications');

export function Notifications() {
    const items = useSelector(selectNotifications);

    return (
        <div className={cn('wrap')}>
            <div className={cn()}>
                {items.map((item) => (
                    <Notification key={item.id} notification={item} />
                ))}
            </div>
        </div>
    );
}

interface NotificationProps {
    notification: NotificationData;
}

function Notification({notification}: NotificationProps) {
    const notificationType = notification.type;

    if (isStandardNotification(notificationType)) {
        return <StandardNotification notification={notification} />;
    }

    switch (notificationType) {
        case NotificationType.USERS_IMPORT:
            return <UsersImportNotification notificationId={notification.id} />;
    }
}

interface StandardNotificationProps {
    notification: NotificationData;
}

function StandardNotification({notification}: StandardNotificationProps) {
    const {isClosing} = useNotificationAutoClose({disabled: notification.permanent});
    useNotificationCloseAnimation({notificationId: notification.id, isClosing});

    return (
        <div className={cn('notification', {type: notification.type, closing: isClosing})}>
            <NotificationIcon className={cn('notification-icon')} type={notification.type} size={20} />
            {notification.text}
        </div>
    );
}
