import {type PayloadAction, createSlice} from '@reduxjs/toolkit';

interface UsersListState {
    addedUserId?: string;
}

const initialState: UsersListState = {};

export const usersListSlice = createSlice({
    name: 'users-list',
    initialState,
    reducers: {
        setAddedUserId: (state, {payload: addedUserId}: PayloadAction<string>) => {
            state.addedUserId = addedUserId;
        },
        clearAddedUserId: (state) => {
            state.addedUserId = undefined;
        }
    }
});

export const {actions, reducer} = usersListSlice;

export {actions as usersListActions, reducer as usersListReducer};
