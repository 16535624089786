export const ROUTES = {
    API_PRIVATE: {
        BASE: '/api/private',
        V1: {
            BASE: '/v1',
            CREATE_COMPANY: '/create-company',
            IDM: {
                BASE: '/idm',
                INFO: 'info',
                PERMISSIONS: 'permissions',
                ADD_USER_ROLE: 'add-role',
                REMOVE_USER_ROLE: 'remove-role',
                GET_ROLES: 'get-roles',
                UPSERT_ROLE: 'upsert-constructor-role',
                REMOVE_ROLE: 'remove-constructor-role'
            }
        }
    },
    API_EXTERNAL: {
        BASE: '/api/external',
        V1: {
            BASE: '/v1',
            GET_USERS_IMPORT_TEMPLATE: '/get-users-import-template',
            PARTNER_OR_COMPANY: {
                BASE: '/:partnerOrCompanyId',
                IDP: {
                    BASE: 'idp',
                    REFRESH: 'refresh',
                    /** @deprecated */
                    GLOBAL_MDA: 'global-mda'
                }
            },
            OTP_TIMER: {
                BASE: '/otp-timer',
                ADD_OTP_TIMER: 'add-otp-timer'
            },
            COURIER: {
                BASE: '/courier',
                RESET_COURIER_PASSCODE: 'reset-courier-passcode',
                GET_COURIER_AUTH_SETTINGS: 'get-courier-auth-settings',
                UPDATE_COURIER_AUTH_SETTINGS: 'update-courier-auth-settings'
            }
        }
    },
    API: {
        V1: {
            BASE: '/api/v1',
            /**
             * @deprecated
             */
            DETERMINE_PARTNER: 'determine-partner',
            DETERMINE_COMPANY: 'determine-company',
            LOGGER: {
                BASE: 'log',
                ERROR: 'error'
            },
            PARTNER_OR_COMPANY: {
                BASE: '/:partnerOrCompanyId',
                IDP: {
                    BASE: 'idp',
                    REFRESH: 'refresh',
                    LOGOUT: 'logout'
                },
                USERS: {
                    BASE: 'users',
                    GET: 'get',
                    GET_LIST: 'get-list',
                    UPDATE: 'update',
                    CREATE: 'create',
                    DELETE: 'delete',
                    UPDATE_STATUS: 'update-status',
                    RESET_PASSWORD: 'reset-password',
                    UPLOAD_IMPORT_FILE: 'upload-import-file',
                    CREATE_MULTIPLE: 'create-multiple',
                    GET_LAST_IMPORT_FOR_USER: 'get-last-import-for-user',
                    LOGOUT_ANOTHER_USER_GLOBALLY: 'logout-another-user-globally'
                },
                IDM: {
                    BASE: 'idm',
                    GET_PARTNERS: 'get-partners',
                    GET_COMPANIES: 'get-companies',
                    GET_SINGLE_USER_ROLES: 'get-single-user-roles',
                    GET_USER_ROLES: 'get-user-roles',
                    GET_USER_ROLE_EVENTS: 'get-user-role-events',
                    GET_ROLES: 'get-roles',
                    REMOVE_USER_ROLE: 'remove-user-role',
                    GET_POLICIES: 'get-policies',
                    GET_POLICY: 'get-policy',
                    GET_POLICY_WITH_APPROVERS: 'get-policy-with-approvers',
                    REMOVE_POLICY: 'remove-policy',
                    CREATE_POLICY: 'create_policy',
                    UPDATE_POLICY: 'update_policy',
                    REVOKE_ALL_ROLES: 'revoke-all-roles',
                    BULK_CHANGE_ROLES: 'bulk-change-roles',
                    ADD_POLICY_ROLES: 'add-policy-roles',
                    REMOVE_POLICY_ROLES: 'remove-policy-roles',
                    ADD_POLICY_ROLE_COMPANIES: 'add-policy-role-companies',
                    REMOVE_POLICY_ROLE_COMPANIES: 'remove-policy-role-companies',
                    GET_USERS: 'get-users',
                    GET_USER_ROLE_REQUESTS: 'get-user-role-requests',
                    BULK_REQUEST_ROLES: 'bulk-request-roles',
                    RESOLVE_USER_ROLE_REQUESTS: 'resolve-user-role-requests',
                    GET_PENDING_USER_ROLE_REQUESTS_COUNT: 'get-pending-user-role-requests-count',
                    GET_ROLE: 'get-role',
                    UPSERT_ROLE: 'upsert-role',
                    REMOVE_ROLE: 'remove-role',
                    GET_PERMISSIONS: 'get-permissions',
                    GET_USER_ROLE_WITH_APPROVERS: 'get-user-role-with-approvers',
                    EXPORT_ROLES: 'export-roles',
                    IMPORT_ROLES: 'import-roles',
                    DRAFTS: {
                        BASE: 'drafts',
                        CREATE_POLICY: 'create-policy',
                        GET_POLICY: 'get-policy',
                        UPDATE_POLICY: 'update-policy',
                        DELETE_POLICY: 'delete-policy',
                        GET_POLICIES_LIST: 'get-policies-list',
                        GET_REQUESTS_LIST: 'get-requests-list'
                    }
                }
            },
            OTP_TIMER: {
                BASE: 'otp-timer',
                GET_OTP_TIMER: 'get-otp-timer'
            },
            COURIER_SIGN_IN: {
                BASE: 'courier-sign-in',
                MANIFEST: 'manifest',
                INFO_BY_PHONE: 'info-by-phone',
                COURIER_OAUTH_LOGIN: 'courier-oauth-login',
                COURIERS_LIST: 'couriers-list',
                COURIER_AUTH: 'courier-auth',
                PARTNER_OR_COMPANY: {
                    BASE: ':partnerOrCompanyId',
                    REFRESH: 'refresh',
                    SET_PASSCODE: 'set-passcode'
                }
            }
        }
    },
    PAGES: {
        ROOT: '/',
        CALLBACK: {
            BASE: '/callback',
            INDEX: '',
            GLOBAL_MDA: '/global-mda'
        },
        /** @deprecated use /select-company instead */
        GLOBAL_LOGIN: '/global/login',
        PARTNER_OR_COMPANY: {
            BASE: '/:partnerOrCompanyId',
            USERS: {
                BASE: '/users',
                LIST: '',
                USER_VIEW: {
                    BASE: '/:userId',
                    ROLES: {
                        BASE: '/roles',
                        VIEW: '/:roleId'
                    }
                },
                IMPORT_PREVIEW: '/import-preview',
                ROLES: {
                    BASE: '/roles',
                    LIST: '/list',
                    EVENTS: '/events',
                    POLICIES: '/policies',
                    REQUESTS: {
                        BASE: '/requests',
                        VIEW: '/:roleId'
                    }
                },
                ROLE: {
                    BASE: '/role',
                    CREATE: '/create/:roleEditTab?',
                    EDIT: '/:roleId/:roleEditTab?'
                }
            },
            POLICIES: {
                BASE: '/policies',
                VIEW: '/:policyId/:policyTab?'
            },
            DRAFTS: {
                BASE: '/drafts',
                REQUESTS: {
                    BASE: '/requests',
                    LIST: '/list'
                },
                POLICIES: {
                    BASE: '/policies',
                    LIST: '/list',
                    CREATE: '/create',
                    EDIT: '/:policyId'
                }
            }
        },
        COMPANY: {
            /**
             * Здесь временно partnerOrCompanyId.
             * После LAVKAIAM-373 он переименуется обратно в companyId
             */
            BASE: '/companies/:partnerOrCompanyId'
        },
        COMMON: {
            LOGOUT: '/logout',
            LOGIN: {
                BASE: '/login',
                COOKIE: '/cookie',
                OAUTH: '/oauth',
                SAML: '/saml'
            }
        },
        /**
         * @deprecated
         */
        SELECT_PARTNER: '/select-partner',
        SELECT_COMPANY: '/select-company',
        COURIER_SIGN_IN: '/courier/sign-in',
        NOT_FOUND: '/not-found',
        QUERY_ERROR: '/error'
    }
} as const;

export enum RolesTab {
    LIST = 'list',
    EVENTS = 'events',
    REQUESTS = 'requests',
    POLICIES = 'policies'
}

export const PAGE_PATHS = {
    ROOT: ROUTES.PAGES.ROOT,
    PARTNER_OR_COMPANY_BASE: ROUTES.PAGES.PARTNER_OR_COMPANY.BASE,
    USERS_BASE: [ROUTES.PAGES.PARTNER_OR_COMPANY.BASE, ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.BASE].join(''),
    USERS_LIST: [
        ROUTES.PAGES.PARTNER_OR_COMPANY.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.LIST
    ].join(''),
    USER_ROLES: [
        ROUTES.PAGES.PARTNER_OR_COMPANY.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.LIST,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.ROLES.BASE
    ].join(''),
    USER_ROLES_LIST: [
        ROUTES.PAGES.PARTNER_OR_COMPANY.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.LIST,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.ROLES.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.ROLES.LIST
    ].join(''),
    USER_ROLES_EVENTS: [
        ROUTES.PAGES.PARTNER_OR_COMPANY.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.LIST,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.ROLES.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.ROLES.EVENTS
    ].join(''),
    USER_ROLES_POLICIES: [
        ROUTES.PAGES.PARTNER_OR_COMPANY.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.LIST,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.ROLES.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.ROLES.POLICIES
    ].join(''),
    USER_ROLES_REQUESTS: [
        ROUTES.PAGES.PARTNER_OR_COMPANY.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.LIST,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.ROLES.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.ROLES.REQUESTS.BASE
    ].join(''),
    USER_ROLES_REQUESTS_VIEW: [
        ROUTES.PAGES.PARTNER_OR_COMPANY.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.LIST,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.ROLES.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.ROLES.REQUESTS.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.ROLES.REQUESTS.VIEW
    ].join(''),
    USER_VIEW: [
        ROUTES.PAGES.PARTNER_OR_COMPANY.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.USER_VIEW.BASE
    ].join(''),
    USER_VIEW_ROLES: [
        ROUTES.PAGES.PARTNER_OR_COMPANY.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.USER_VIEW.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.USER_VIEW.ROLES.BASE
    ].join(''),
    USER_VIEW_ROLES_VIEWS: [
        ROUTES.PAGES.PARTNER_OR_COMPANY.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.USER_VIEW.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.USER_VIEW.ROLES.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.USER_VIEW.ROLES.VIEW
    ].join(''),
    USER_IMPORT_PREVIEW: [
        ROUTES.PAGES.PARTNER_OR_COMPANY.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.IMPORT_PREVIEW
    ].join(''),
    POLICIES_LIST: [
        ROUTES.PAGES.PARTNER_OR_COMPANY.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.ROLES.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.ROLES.POLICIES
    ].join(''),
    POLICIES_BASE: [ROUTES.PAGES.PARTNER_OR_COMPANY.BASE, ROUTES.PAGES.PARTNER_OR_COMPANY.POLICIES.BASE].join(''),
    POLICY_VIEW: [
        ROUTES.PAGES.PARTNER_OR_COMPANY.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.POLICIES.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.POLICIES.VIEW
    ].join(''),
    ROLE_CREATE: [
        ROUTES.PAGES.PARTNER_OR_COMPANY.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.ROLE.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.ROLE.CREATE
    ].join(''),
    ROLE_EDIT: [
        ROUTES.PAGES.PARTNER_OR_COMPANY.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.ROLE.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.USERS.ROLE.EDIT
    ].join(''),
    DRAFTS_BASE: [ROUTES.PAGES.PARTNER_OR_COMPANY.BASE, ROUTES.PAGES.PARTNER_OR_COMPANY.DRAFTS.BASE].join(''),
    DRAFT_REQUESTS_BASE: [
        ROUTES.PAGES.PARTNER_OR_COMPANY.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.DRAFTS.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.DRAFTS.REQUESTS
    ].join(''),
    DRAFT_REQUESTS_LIST: [
        ROUTES.PAGES.PARTNER_OR_COMPANY.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.DRAFTS.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.DRAFTS.REQUESTS.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.DRAFTS.REQUESTS.LIST
    ].join(''),
    DRAFT_POLICIES_BASE: [
        ROUTES.PAGES.PARTNER_OR_COMPANY.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.DRAFTS.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.DRAFTS.POLICIES
    ].join(''),
    DRAFT_POLICIES_LIST: [
        ROUTES.PAGES.PARTNER_OR_COMPANY.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.DRAFTS.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.DRAFTS.POLICIES.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.DRAFTS.POLICIES.LIST
    ].join(''),
    DRAFT_POLICY_CREATE: [
        ROUTES.PAGES.PARTNER_OR_COMPANY.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.DRAFTS.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.DRAFTS.POLICIES.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.DRAFTS.POLICIES.CREATE
    ].join(''),
    DRAFT_POLICY_EDIT: [
        ROUTES.PAGES.PARTNER_OR_COMPANY.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.DRAFTS.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.DRAFTS.POLICIES.BASE,
        ROUTES.PAGES.PARTNER_OR_COMPANY.DRAFTS.POLICIES.EDIT
    ].join(''),
    LOGIN_COOKIE: [
        ROUTES.PAGES.PARTNER_OR_COMPANY.BASE,
        ROUTES.PAGES.COMMON.LOGIN.BASE,
        ROUTES.PAGES.COMMON.LOGIN.COOKIE
    ].join(''),
    LOGOUT: [ROUTES.PAGES.PARTNER_OR_COMPANY.BASE, ROUTES.PAGES.COMMON.LOGOUT].join(''),
    /**
     * @deprecated
     */
    SELECT_PARTNER: ROUTES.PAGES.SELECT_PARTNER,
    SELECT_COMPANY: ROUTES.PAGES.SELECT_COMPANY,
    COURIER_SIGN_IN: ROUTES.PAGES.COURIER_SIGN_IN,
    NOT_FOUND: ROUTES.PAGES.NOT_FOUND,
    QUERY_ERROR: ROUTES.PAGES.QUERY_ERROR
} as const;

export const API_PATHS = {
    GET_USERS_IMPORT_TEMPLATE: [
        ROUTES.API_EXTERNAL.BASE,
        ROUTES.API_EXTERNAL.V1.BASE,
        ROUTES.API_EXTERNAL.V1.GET_USERS_IMPORT_TEMPLATE
    ].join('')
} as const;

export const QUERY_KEYS = {};
